@tailwind base;
@tailwind components;
@tailwind utilities;

.hidden-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.hidden-scrollbar::-webkit-scrollbar {
    display: none;
}

.reportClass {
    /* height: 1200px; */
    background-color: #121212;
    height: calc(100vh - 80px) !important;
}

input[type="radio"] {
    background-color: blue;
}

blockquote>p {
    font-size: inherit !important;
    line-height: inherit !important;
    font-weight: inherit !important;
    font-family: inherit !important;
    font-style: inherit !important;
}

/* For the scrollbar itself */
::-webkit-scrollbar {
    width: 12px;
    /* Adjust scrollbar width */
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    /* Handle color */
    border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Handle color on hover */
}

@font-face {
    font-family: "recoleta";
    src: url("/fonts/Recoleta/Recoleta-Black.otf");
    font-weight: 900;
}

@font-face {
    font-family: "recoleta";
    src: url("/fonts/Recoleta/Recoleta-Bold.otf");
    font-weight: 700;
}

@font-face {
    font-family: "recoleta";
    src: url("/fonts/Recoleta/Recoleta-SemiBold.otf");
    font-weight: 600;
}

@font-face {
    font-family: "recoleta";
    src: url("/fonts/Recoleta/Recoleta-Medium.otf");
    font-weight: 500;
}

@font-face {
    font-family: "recoleta";
    src: url("/fonts/Recoleta/Recoleta-Regular.otf");
    font-weight: 400;
}

@font-face {
    font-family: "recoleta";
    src: url("/fonts/Recoleta/Recoleta-Light.otf");
    font-weight: 200;
}

@font-face {
    font-family: "recoleta";
    src: url("/fonts/Recoleta/Recoleta-Thin.otf");
    font-weight: 100;
}

@layer base {
    :root {
        --foreground: 37 32% 92%;

        --card: 226.7 12.7% 13.9%;
        --card-foreground: 37 32% 92%;

        --popover: 226.7 12.7% 13.9%;
        --popover-foreground: 210 40% 98%;

        --primary-foreground: 222.2 47.4% 11.2%;

        --secondary: 226.7 12.7% 17.5%;
        --secondary-foreground: 210 40% 98%;

        --muted: 0 0% 15%;
        --muted-foreground: 0 0% 51%;

        --accent: 226.7 12.7% 17.5%;
        --accent-foreground: 210 40% 98%;

        --destructive: 14 86% 49%;
        --destructive-foreground: 210 40% 98%;

        --border: 226.7 12.7% 17.5%;
        --input: 0 0% 20%;
        --ring: 0 0% 61%;

        --radius: 0.5rem;

        --font-recoleta: 'Recoleta';
    }
}

@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background-900 text-foreground;
    }
}

@layer utilities {
    @variants responsive {

        /* Hide scrollbar for Chrome, Safari and Opera */
        .no-scrollbar::-webkit-scrollbar {
            display: none;
            margin-right: 0 !important;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        .no-scrollbar {
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */
        }
    }
}

.fast-option.fast-option {
    padding-left: 8px;
    font-size: 14px;
}

.fast-option-focused.fast-option-focused {
    background-color: rgba(255, 255, 255, 0.05);
}

.fast-option-selected.fast-option-selected {
    background-color: rgba(255, 255, 255, 0.2);
}

.fast-option-selected.fast-option-selected.fast-option-focused.fast-option-focused {
    text-decoration: underline;
}

.linear-pure-progress {
    background-size: 200% 100%;
    background-image: linear-gradient(to right, transparent 50%, currentColor 50%, currentColor 60%, transparent 60%, transparent 71.5%, currentColor 71.5%, currentColor 84%, transparent 84%);
    animation: linear-pure-progress-anim 2s infinite linear;
}


@keyframes linear-pure-progress-anim {
    0% {
        background-size: 200% 100%;
        background-position: left -31.25% top 0%;
    }
    50% {
        background-size: 800% 100%;
        background-position: left -49% top 0%;
    }
    100% {
        background-size: 400% 100%;
        background-position: left -102% top 0%;
    }
}
